import React from 'react';

// Import styles
import './styles.scss';

// Import helpers
import { MEDIA_QUERY_SIZE } from 'helpers/styles';

// Import components
import { Image } from 'components/elements';

// Import utilities
import { useMediaQuery } from 'components/utilities';
import { arrayOf, node, oneOfType, string } from 'prop-types';

const BackgroundContent = ({ className = '', children }) => {
	const match = useMediaQuery(`(min-width: ${MEDIA_QUERY_SIZE.md}px)`);
	const matchLg = useMediaQuery(`(min-width: ${MEDIA_QUERY_SIZE.lg}px)`);

	return (
		<>
			{match && (
				<Image
					fileName="start_background.png"
					className={`background-image absolute top-0 right-0 object-cover h-full z-0`}
				/>
			)}
			<div
				className={`${
					match && 'fadeBox'
				} inherit-height absolute top-0 left-0 h-full w-full bg-primary z-0 
				md:w-3/4 md:pr-32
				${matchLg && 'largeWidth'} ${className}`}
			>
				{children}
			</div>
		</>
	);
};

BackgroundContent.propTypes = {
	className: string,
	children: oneOfType([arrayOf(node), node]).isRequired
};

export default BackgroundContent;

import React from 'react';
import AnchorLink from 'react-anchor-link-smooth-scroll';

// Import translations
import { useMediaQuery, useTranslations } from 'components/utilities';

// Import styles
import './styles.scss';

// Import helpers
import { HEADER_BAR_HEIGHT, HEADER_HREF } from 'helpers';

// Import components
import { Section, Typography, Button, Image } from 'components/elements';

// Import helpers
import { MEDIA_QUERY_SIZE } from 'helpers/styles';

const About = () => {
	const { t } = useTranslations();

	const matchSmall = useMediaQuery(`(min-width: ${MEDIA_QUERY_SIZE.sm}px)`);
	const matchLarge = useMediaQuery(`(min-width: ${MEDIA_QUERY_SIZE.lg}px)`);
	const matchHeight = useMediaQuery(
		`(max-height: ${MEDIA_QUERY_SIZE.HEIGHT.sm}px)`
	);

	return (
		<Section id={HEADER_HREF.ABOUT} isMinHeight={matchHeight}>
			<div
				className={`inherit-height container relative mx-auto h-full flex justify-between items-center`}
			>
				{matchLarge && (
					<div
						className={`flex-1 flex justify-center items-center pl-3 py-10`}
					>
						<Image
							fileName={'about_image.png'}
							alt="background"
							className={`w-full`}
						/>
					</div>
				)}
				<div
					className={`flex-1 px-5 w-full h-full flex flex-col justify-center items-center`}
				>
					<div className={`${matchSmall && 'content-wrapper'} py-5`}>
						<Typography
							className={`text-4xl font-semibold text-black pb-2 
							xl:text-5xl`}
						>
							{t('about.title')}
						</Typography>
						<Typography
							className={`text-black font-normal py-4 pb-10
							xl:text-xl`}
						>
							{t('about.content')}
						</Typography>
						<AnchorLink
							offset={HEADER_BAR_HEIGHT}
							href={`#${HEADER_HREF.DOWNLOAD}`}
						>
							<Button className={`rounded-full w-full`}>
								{t('about.button')}
							</Button>
						</AnchorLink>
					</div>
				</div>
			</div>
		</Section>
	);
};

export default About;

import BakingIcon from 'assets/icons/baking_icon.svg';
import FirstAidIcon from 'assets/icons/first_aid_icon.svg';
import StrengthIcon from 'assets/icons/strength_icon.svg';
import HeartIcon from 'assets/icons/heart_icon.svg';

export const LIST_ITEMS = [
	{
		icon: BakingIcon,
		label: 'functions.list.baking'
	},
	{
		icon: FirstAidIcon,
		label: 'functions.list.first_aid'
	},
	{
		icon: StrengthIcon,
		label: 'functions.list.strength'
	},
	{
		icon: HeartIcon,
		label: 'functions.list.heart'
	}
];

import React from 'react';

// Import translations
import { useMediaQuery } from 'components/utilities';

// Import styles
import './styles.scss';

// Import helpers
import { HEADER_HREF } from 'helpers';

// Import components
import { Section, Image } from 'components/elements';
import Description from './Description';

// Import helpers
import { MEDIA_QUERY_SIZE } from 'helpers/styles';

const Functions = () => {
	const matchLarge = useMediaQuery(`(min-width: ${MEDIA_QUERY_SIZE.lg}px)`);

	const containerRef = React.useRef(null);

	return (
		<Section
			id={HEADER_HREF.FUNCTIONS}
			className={`bg-primary image-wrapper`}
			isMinHeight
			ref={containerRef}
		>
			<div
				className={`inherit-height container relative mx-auto h-full flex justify-between items-center overflow-hidden`}
			>
				<Description />
				{matchLarge && (
					<div className={`inherit-height`} style={{ flex: 1 }}>
						<Image
							fileName="functions_image.png"
							alt="background"
							className={`inherit-height h-full object-cover`}
						/>
					</div>
				)}
			</div>
		</Section>
	);
};

export default Functions;

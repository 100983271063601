import React from 'react';

import { HEADER_HREF } from 'helpers';
import { MEDIA_QUERY_SIZE } from 'helpers/styles';
import { DOWNLOAD_ITEMS } from './helpers';
import { Section, Logo, Typography, Downloader } from 'components/elements';
import { BackgroundContent } from './components';
import { useMediaQuery, useTranslations } from 'components/utilities';

const Start = () => {
	const { t } = useTranslations();
	const matchHeight = useMediaQuery(
		`(max-height: ${MEDIA_QUERY_SIZE.HEIGHT.sm}px)`
	);

	const smallHeightRoot = matchHeight ? 'relative' : '';
	return (
		<Section id={HEADER_HREF.START} isMinHeight={matchHeight}>
			<BackgroundContent className={smallHeightRoot}>
				<div
					className={`inherit-height container relative mx-auto h-full w-full flex flex-col justify-center p-5 max-w-500`}
				>
					<Logo variant="light" />
					<Typography
						className={`text-4xl font-semibold text-white py-2 
							xl:py-8 xl:text-5xlCustom`}
					>
						{t('start.header')}
					</Typography>
					<Typography
						className={`text-white font-normal 
							xl:text-xl`}
					>
						{t('start.text')}
					</Typography>
					<div
						className={`flex justify-evenly items-center w-full pt-5 md:justify-between xl:pt-10`}
					>
						{DOWNLOAD_ITEMS.map((props) => (
							<Downloader key={props.variant} {...props} />
						))}
					</div>
				</div>
			</BackgroundContent>
		</Section>
	);
};

export default Start;

import React from 'react';

// Import styles
import './styles.scss';

// Import translations
import { useMediaQuery, useTranslations } from 'components/utilities';

// Import helpers
import { MEDIA_QUERY_SIZE } from 'helpers/styles';
import { LIST_ITEMS } from './helpers';

// Import components
import { Typography } from 'components/elements';

const Description = () => {
	const { t } = useTranslations();
	const matchSmall = useMediaQuery(`(min-width: ${MEDIA_QUERY_SIZE.sm}px)`);

	const renderItem = ({ label, icon: Icon }) => (
		<li key={label} className={`flex py-2 md:pb-5 items-center`}>
			<div className={'icon-wrapper'}>
				<Icon />
			</div>
			<Typography className={`text-white xl:text-lg pl-8`}>
				{t(label)}
			</Typography>
		</li>
	);

	return (
		<div
			className={`flex-1 p-5 w-full h-full flex flex-col justify-center items-center`}
		>
			<div className={`${matchSmall && 'wrapper'}`}>
				<Typography className={`text-4xl font-semibold text-white`}>
					{t('functions.title')}
				</Typography>
				<Typography
					className={`text-white font-normal py-2 pb-10
							xl:text-xl`}
				>
					{t('functions.content')}
				</Typography>
				<ul>{LIST_ITEMS.map(renderItem)}</ul>
			</div>
		</div>
	);
};

export default Description;
